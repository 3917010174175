import React from "react";

import { graphql } from 'gatsby'
import Img from 'gatsby-image';

import Header from '../components/header';
import Footer from '../components/footer';
import Meta from '../components/meta';

import {FaFacebookF} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';

export const query = graphql`
  query {
    vancouverImage: file(relativePath: { regex: "/^vancouver.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    veronaImage: file(relativePath: { regex: "/^verona.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    vancouverMap: file(relativePath: { regex: "/^vancouver-map.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    veronaMap: file(relativePath: { regex: "/^verona-map.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
  }
`

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      vancouverHour: null,
      veronaHour: null,
      minute: null,
    }

    this.updateTime = this.updateTime.bind(this);
  };

  componentDidMount() {
    this.updateTime();
  };

  componentWillUnmount() {
    this.updateTime = function() { return };
  }

  updateTime = () => {
    var vancouverTime;
    var veronaTime;

    // Get offset and utc date
    const vancouverOffset = -7;
    const veronaOffset = 2;
    var date = new Date();
    var utc = date.getTime() + (date.getTimezoneOffset() * 60000);

    // Get time for vancouver and verona, split into hour/minute/second array.
    veronaTime = new Date(utc + (3600000 * veronaOffset)).toLocaleTimeString(undefined, {hour12: false, hour:'numeric', minute: 'numeric', second:'numeric'});
    vancouverTime = new Date(utc + (3600000 * vancouverOffset)).toLocaleTimeString(undefined, {hour12: false, hour:'numeric', minute:'numeric', second:'numeric'});
    veronaTime = veronaTime.split(':');
    vancouverTime = vancouverTime.split(':');


    this.setState({
      vancouverHour: vancouverTime[0],
      veronaHour: veronaTime[0],
      minute: vancouverTime[1]
    })

    // Re-run function to update time every minute
    setTimeout(() => {
      this.updateTime();
    }, (60 - vancouverTime[2])*1000 );

  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="vh-100">
        <Meta title="Contact Us" />
        <Header darkFont={true}/>

        <div className="p-5 container-fluid off-white-background">
          <div className="row justify-content-center mt-5">
            <div className="col-12 text-center mt-5 pt-5">
              <h1 className="text-dark serif mt-0 display-4 d-inline-block text-nowrap">Contact Us</h1>
              <hr className="col-1 horizontal-divider my-0"></hr>
              <p className="sans-serif text-dark mt-3">Would you like to learn more about one of our products?</p>
              <p className="sans-serif text-dark">Do you have any upcoming projects that you would like to discuss?</p>
              <p className="sans-serif text-dark">Please get in touch using the details below:</p>
            </div>
          </div>

          <div className="row justify-content-around py-5 px-0 px-sm-5">
            <div className="col-12 col-md-4 text-center pt-5">
              <h2 className="serif">VANCOUVER</h2>
              <div className="position-relative off-white-background">
                <Img className="dim-image" fluid={this.props.data.vancouverImage.childImageSharp.fluid} alt=""/>
                <h1 className="position-absolute display-2 serif pb-2 text-white local-time">{this.state.vancouverHour}<span className="pulse">&#x3a;</span>{this.state.minute}</h1>
              </div>
              <div className="my-5">
                <p className="text-dark sans-serif text-center text-md-left font-weight-bold mb-0">Head Office</p>
                <p className="text-dark sans-serif text-center text-md-left font-weight-bold">Vancouver Jade</p>
                <p className="text-dark sans-serif text-center text-md-left mt-4 mb-0 text-nowrap">460 - 688 W Hastings St</p>
                <p className="text-dark sans-serif text-center text-md-left mb-0 text-nowrap">Vancouver, BC V6B 1P1</p>
                <p className="text-dark sans-serif text-center text-md-left mb-0">Canada</p>
                <p className="text-dark sans-serif text-center text-md-left mt-4 mb-0">info@vancouverjade.com</p>
                <p className="text-dark sans-serif text-center text-md-left mb-0">+1 (778) 231 - 5669</p>
              </div>
              <Img className="dim-image" fluid={this.props.data.vancouverMap.childImageSharp.fluid} alt=""/>
            </div>
            <div className="col-12 col-md-4 text-center pt-5">
              <h2 className="text-dark serif">VERONA</h2>
              <div className="position-relative off-white-background">
              <Img className="dim-image" fluid={this.props.data.veronaImage.childImageSharp.fluid} alt=""/>
                <h1 className="position-absolute display-2 serif pb-2 text-white local-time">{this.state.veronaHour}<span className="pulse">&#x3a;</span>{this.state.minute}</h1>
              </div>
              <div className="my-5">
                <p className="text-dark sans-serif text-center text-md-left font-weight-bold mb-0">European Sales</p>
                <p className="text-dark sans-serif text-center text-md-left font-weight-bold">Pure Natural Stone</p>
                <p className="text-dark sans-serif text-center text-md-left mt-4 mb-0 text-nowrap">Loc. Spighetta delle Querce 11</p>
                <p className="text-dark sans-serif text-center text-md-left mb-0 text-nowrap">37010 Torri del Benaco (VR)</p>
                <p className="text-dark sans-serif text-center text-md-left mb-0">Italy</p>
                <p className="text-dark sans-serif text-center text-md-left mt-4 mb-0">jurjen@purenaturalstone.com</p>
                <p className="text-dark sans-serif text-center text-md-left mb-0">0039 - 3452109669</p>
              </div>
              <Img className="dim-image" fluid={this.props.data.veronaMap.childImageSharp.fluid} alt=""/>
            </div>
          </div>

          <div className="row justify-content-center"> 
            <div className="col mt-3">
              <h2 className="text-dark serif text-center">Get in Touch</h2>
              <hr className="col-1 horizontal-divider my-0"></hr>
            </div>
          </div>

          <div className="row justify-content-around mt-4">
            <div className="col-10 col-md-7">
              <form name="contact" method="post" action="/thank-you" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label><input name="bot-field"></input></label>
                </p>
                <div className="form-group">
                  <label className="sans-serif" htmlFor="nameInput">Name &#x2a;</label>
                  <input type="text" name="name" onChange={this.handleChange} className="form-control border-secondary rounded-0" id="nameInput" ></input>
                </div>
                <div className="form-group">
                  <label className="sans-serif" htmlFor="emailInput">Email &#x2a;</label>
                  <input type="email" name="email" onChange={this.handleChange} className="form-control border-secondary rounded-0" id="emailInput"></input>
                </div>
                <div className="form-group">
                  <label className="sans-serif" htmlFor="enquiryInput">Enquiries &#x2a;</label>
                  <textarea name="enquiry" onChange={this.handleChange} className="form-control border-secondary rounded-0" id="enquiryInput" rows="4"></textarea>
                </div>
                <div className="text-center mt-4">
                  <button type="submit" disabled={!this.state.email || !this.state.name || !this.state.enquiry} className="btn btn-outline-dark thick-border rounded-0 py-1 px-5 sans-serif">SUBMIT</button>
                </div>
              </form>
            </div>
          </div>


        </div>

        <div className="py-5 px-sm-5 container-fluid black-background">
          <div className="row">
            <div className="col">
              <h2 className="text-white serif text-center">Social Media</h2>
              <hr className="col-1 horizontal-divider my-0"></hr>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-12 col-sm-4 col-md-3 border-right border-dark py-1 py-sm-4 text-center">
              <a href="https://www.instagram.com/vancouverjade/?hl=en" title="Vancouver Jade Instagram" target="_blank" rel="noopener noreferrer"><h1 className="text-white"><FaInstagram className="social-media-link border rounded-circle p-2"/></h1></a>
              <p className="text-white sans-serif mb-0 mt-3">Instagram</p>
              <p className="text-white small sans-serif font-italic mt-2 text-nowrap">&#x40;vancouverjade</p>
            </div>
            <div className="col-12 col-sm-4 col-md-3 py-1 py-sm-4 text-center">
              <a href="https://www.facebook.com/vancouverjadee/" title="Vancouver Jade Facebook" target="_blank" rel="noopener noreferrer" ><h1 className="text-white"><FaFacebookF className="social-media-link border rounded-circle p-2"/></h1></a>
              <p className="text-white sans-serif mb-0 mt-3">Facebook</p>
              <p className="text-white small sans-serif font-italic mt-2 text-nowrap">&#x40;vancouverjade</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>

    )
  }
}

export default Contact;
